import React from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import styled from "styled-components"
import { DEFAULT_BLUE } from "../styles/common"
import { RichText } from "prismic-reactjs"
import Img from "gatsby-image"
import HoverLine from "../components/common/hover-line/dist"
import { graphql } from 'gatsby';

const HeroFooter = styled.div`
  width: 100vw;
  min-height: 30rem;
  display: flex;
  width: 100vw;
  align-self: flex-end;
  flex-direction: column;
  align-items: center;
  color: ${DEFAULT_BLUE};
  text-align: center;
  padding-top: 5rem;
`

const Wrapper = styled.div`
  margin-top: -3rem;
`

const SponsorsWrapper = styled.div`
  margin-top: -10rem;
  padding-bottom: 10rem;
  display: grid;
  grid-gap: 5rem;
  grid-template-columns: repeat(auto-fill, 20rem);
  justify-content: center;
`

const Sponsor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  height: 7rem;
  width: 20rem;
  background:linear-gradient( to bottom, #bcc6cc, #eee, #bcc6cc);
  box-shadow: 5px 5px 8px -3px ${DEFAULT_BLUE};
  color: ${DEFAULT_BLUE};
  font-size: 2rem;
  text-align: center;
  & > h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-weight: 300;
  }
  & > h6 {
    font-size: 1.6rem;
  }
  & > h5 {
    font-size: 1.8rem;
  }
  & > p {
    font-size: 1rem;
    font-style: italic;
  }
`

export const query = graphql`
  {
    prismic {
      allSponsors {
        edges {
          node {
            footer_title
            footer_text
            companies {
              text
            }
            background_image
            background_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const BliSponsor = ({ data }) => {
  const sponsor = data.prismic.allSponsors.edges[0].node
  return (
    <Layout transparent currentSiteUrl='/bli-sponsor' hideFooter>
      <SEO title='Bli  sponsor' />
      <Wrapper>
        <Img
          style={{ minHeight: "27rem" }}
          fluid={sponsor.background_imageSharp.childImageSharp.fluid}
        />
        <SponsorsWrapper>
          {sponsor.companies.map(spon => (
            <Sponsor>{RichText.render(spon.text)}</Sponsor>
          ))}
        </SponsorsWrapper>
      </Wrapper>
      <HeroFooter className='background'>
        <div>
          <h1>{RichText.asText(sponsor.footer_title)}</h1>
          <HoverLine show color={DEFAULT_BLUE} size='0.1' />
        </div>
        <div className='hero'>{RichText.render(sponsor.footer_text)}</div>
      </HeroFooter>
    </Layout>
  )
}
export default BliSponsor
